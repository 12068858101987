"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ASSET_REFERENCE = exports.ASSET_NAMESPACE = exports.CHAIN_REFERENCE = exports.CHAIN_NAMESPACE = exports.solanaChainId = exports.binanceChainId = exports.thorchainChainId = exports.cosmosChainId = exports.baseChainId = exports.arbitrumNovaChainId = exports.arbitrumChainId = exports.gnosisChainId = exports.polygonChainId = exports.bscChainId = exports.optimismChainId = exports.avalancheChainId = exports.ethChainId = exports.ltcChainId = exports.dogeChainId = exports.bchChainId = exports.btcChainId = exports.binanceAssetId = exports.thorchainAssetId = exports.cosmosAssetId = exports.flipAssetId = exports.usdcOnSolanaAssetId = exports.usdcOnArbitrumOneAssetId = exports.usdcAssetId = exports.usdtAssetId = exports.foxAssetId = exports.foxOnArbitrumOneAssetId = exports.foxOnGnosisAssetId = exports.foxyAssetId = exports.foxatarAssetId = exports.uniV2EthFoxArbitrumAssetId = exports.wrappedSolAssetId = exports.solAssetId = exports.baseAssetId = exports.arbitrumNovaAssetId = exports.arbitrumAssetId = exports.gnosisAssetId = exports.polygonAssetId = exports.bscAssetId = exports.optimismAssetId = exports.avalancheAssetId = exports.ethAssetId = exports.ltcAssetId = exports.dogeAssetId = exports.bchAssetId = exports.btcAssetId = void 0;
exports.FEE_ASSET_IDS = exports.VALID_ASSET_NAMESPACE = exports.VALID_CHAIN_IDS = void 0;
exports.btcAssetId = 'bip122:000000000019d6689c085ae165831e93/slip44:0';
exports.bchAssetId = 'bip122:000000000000000000651ef99cb9fcbe/slip44:145';
exports.dogeAssetId = 'bip122:00000000001a91e3dace36e2be3bf030/slip44:3';
exports.ltcAssetId = 'bip122:12a765e31ffd4059bada1e25190f6e98/slip44:2';
exports.ethAssetId = 'eip155:1/slip44:60';
exports.avalancheAssetId = 'eip155:43114/slip44:60';
exports.optimismAssetId = 'eip155:10/slip44:60';
exports.bscAssetId = 'eip155:56/slip44:60';
exports.polygonAssetId = 'eip155:137/slip44:60';
exports.gnosisAssetId = 'eip155:100/slip44:60';
exports.arbitrumAssetId = 'eip155:42161/slip44:60';
exports.arbitrumNovaAssetId = 'eip155:42170/slip44:60';
exports.baseAssetId = 'eip155:8453/slip44:60';
exports.solAssetId = 'solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp/slip44:501';
exports.wrappedSolAssetId = 'solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp/token:So11111111111111111111111111111111111111112';
exports.uniV2EthFoxArbitrumAssetId = 'eip155:42161/erc20:0x5f6ce0ca13b87bd738519545d3e018e70e339c24';
exports.foxatarAssetId = 'eip155:137/erc721:0x2e727c425a11ce6b8819b3004db332c12d2af2a2';
exports.foxyAssetId = 'eip155:1/erc20:0xdc49108ce5c57bc3408c3a5e95f3d864ec386ed3';
exports.foxOnGnosisAssetId = 'eip155:100/erc20:0x21a42669643f45bc0e086b8fc2ed70c23d67509d';
exports.foxOnArbitrumOneAssetId = 'eip155:42161/erc20:0xf929de51d91c77e42f5090069e0ad7a09e513c73';
exports.foxAssetId = 'eip155:1/erc20:0xc770eefad204b5180df6a14ee197d99d808ee52d';
exports.usdtAssetId = 'eip155:1/erc20:0xdac17f958d2ee523a2206206994597c13d831ec7';
exports.usdcAssetId = 'eip155:1/erc20:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48';
exports.usdcOnArbitrumOneAssetId = 'eip155:42161/erc20:0xaf88d065e77c8cc2239327c5edb3a432268e5831';
exports.usdcOnSolanaAssetId = 'solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp/token:EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v';
exports.flipAssetId = 'eip155:1/erc20:0x826180541412d574cf1336d22c0c0a287822678a';
exports.cosmosAssetId = 'cosmos:cosmoshub-4/slip44:118';
exports.thorchainAssetId = 'cosmos:thorchain-1/slip44:931';
exports.binanceAssetId = 'cosmos:binance-chain-tigris/slip44:714';
exports.btcChainId = 'bip122:000000000019d6689c085ae165831e93';
exports.bchChainId = 'bip122:000000000000000000651ef99cb9fcbe';
exports.dogeChainId = 'bip122:00000000001a91e3dace36e2be3bf030';
exports.ltcChainId = 'bip122:12a765e31ffd4059bada1e25190f6e98';
exports.ethChainId = 'eip155:1';
exports.avalancheChainId = 'eip155:43114';
exports.optimismChainId = 'eip155:10';
exports.bscChainId = 'eip155:56';
exports.polygonChainId = 'eip155:137';
exports.gnosisChainId = 'eip155:100';
exports.arbitrumChainId = 'eip155:42161';
exports.arbitrumNovaChainId = 'eip155:42170';
exports.baseChainId = 'eip155:8453';
exports.cosmosChainId = 'cosmos:cosmoshub-4';
exports.thorchainChainId = 'cosmos:thorchain-1';
exports.binanceChainId = 'cosmos:binance-chain-tigris';
exports.solanaChainId = 'solana:5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp';
exports.CHAIN_NAMESPACE = {
    Evm: 'eip155',
    Utxo: 'bip122',
    CosmosSdk: 'cosmos',
    Solana: 'solana',
};
exports.CHAIN_REFERENCE = {
    EthereumMainnet: '1',
    BitcoinMainnet: '000000000019d6689c085ae165831e93',
    BitcoinCashMainnet: '000000000000000000651ef99cb9fcbe',
    DogecoinMainnet: '00000000001a91e3dace36e2be3bf030',
    LitecoinMainnet: '12a765e31ffd4059bada1e25190f6e98',
    CosmosHubMainnet: 'cosmoshub-4',
    ThorchainMainnet: 'thorchain-1',
    BinanceMainnet: 'binance-chain-tigris',
    AvalancheCChain: '43114',
    OptimismMainnet: '10',
    BnbSmartChainMainnet: '56',
    PolygonMainnet: '137',
    GnosisMainnet: '100',
    ArbitrumMainnet: '42161',
    ArbitrumNovaMainnet: '42170',
    BaseMainnet: '8453',
    SolanaMainnet: '5eykt4UsFv8P8NJdTREpY1vzqKqZKvdp', // https://namespaces.chainagnostic.org/solana/caip2
};
exports.ASSET_NAMESPACE = {
    erc20: 'erc20',
    erc721: 'erc721',
    erc1155: 'erc1155',
    bep20: 'bep20',
    bep721: 'bep721',
    bep1155: 'bep1155',
    slip44: 'slip44',
    ibc: 'ibc',
    splToken: 'token',
};
exports.ASSET_REFERENCE = {
    Bitcoin: '0',
    BitcoinCash: '145',
    Litecoin: '2',
    Dogecoin: '3',
    Cosmos: '118',
    Thorchain: '931',
    Binance: '714',
    Ethereum: '60',
    AvalancheC: '60',
    Optimism: '60',
    BnbSmartChain: '60',
    Polygon: '60',
    Gnosis: '60',
    Arbitrum: '60',
    ArbitrumNova: '60',
    Base: '60',
    Solana: '501',
};
exports.VALID_CHAIN_IDS = Object.freeze({
    [exports.CHAIN_NAMESPACE.Utxo]: [
        exports.CHAIN_REFERENCE.BitcoinMainnet,
        exports.CHAIN_REFERENCE.BitcoinCashMainnet,
        exports.CHAIN_REFERENCE.DogecoinMainnet,
        exports.CHAIN_REFERENCE.LitecoinMainnet,
    ],
    [exports.CHAIN_NAMESPACE.Evm]: [
        exports.CHAIN_REFERENCE.EthereumMainnet,
        exports.CHAIN_REFERENCE.AvalancheCChain,
        exports.CHAIN_REFERENCE.OptimismMainnet,
        exports.CHAIN_REFERENCE.BnbSmartChainMainnet,
        exports.CHAIN_REFERENCE.PolygonMainnet,
        exports.CHAIN_REFERENCE.GnosisMainnet,
        exports.CHAIN_REFERENCE.ArbitrumMainnet,
        exports.CHAIN_REFERENCE.ArbitrumNovaMainnet,
        exports.CHAIN_REFERENCE.BaseMainnet,
    ],
    [exports.CHAIN_NAMESPACE.CosmosSdk]: [
        exports.CHAIN_REFERENCE.CosmosHubMainnet,
        exports.CHAIN_REFERENCE.ThorchainMainnet,
        exports.CHAIN_REFERENCE.BinanceMainnet,
    ],
    [exports.CHAIN_NAMESPACE.Solana]: [exports.CHAIN_REFERENCE.SolanaMainnet],
});
exports.VALID_ASSET_NAMESPACE = Object.freeze({
    [exports.CHAIN_NAMESPACE.Utxo]: [exports.ASSET_NAMESPACE.slip44],
    [exports.CHAIN_NAMESPACE.Evm]: [
        exports.ASSET_NAMESPACE.slip44,
        exports.ASSET_NAMESPACE.erc20,
        exports.ASSET_NAMESPACE.erc721,
        exports.ASSET_NAMESPACE.erc1155,
        exports.ASSET_NAMESPACE.bep20,
        exports.ASSET_NAMESPACE.bep721,
        exports.ASSET_NAMESPACE.bep1155,
    ],
    [exports.CHAIN_NAMESPACE.CosmosSdk]: [exports.ASSET_NAMESPACE.ibc, exports.ASSET_NAMESPACE.slip44],
    [exports.CHAIN_NAMESPACE.Solana]: [exports.ASSET_NAMESPACE.splToken, exports.ASSET_NAMESPACE.slip44],
});
// We should prob change this once we add more chains
exports.FEE_ASSET_IDS = [
    exports.ethAssetId,
    exports.btcAssetId,
    exports.bchAssetId,
    exports.cosmosAssetId,
    exports.thorchainAssetId,
    exports.dogeAssetId,
    exports.ltcAssetId,
    exports.avalancheAssetId,
    exports.optimismAssetId,
    exports.bscAssetId,
    exports.polygonAssetId,
    exports.gnosisAssetId,
    exports.arbitrumAssetId,
    exports.arbitrumNovaAssetId,
    exports.baseAssetId,
    exports.solAssetId,
];
